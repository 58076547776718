import React from 'react';
import CarouselSection from './atoms/CarouselSection';

export default function LandingCaption() {
  return (
    <>
      <div className="relative" style={{ position: 'relative' }}>
        <div className="bg-position absoulte" />
        <div className="bg-arrows-down" />
        <div className="overflow-hidden" id="home">
          <CarouselSection />
        </div>
      </div>
    </>
  );
}
