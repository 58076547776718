import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

import Card from './ProjectCard';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

const img = '/images/landing/carousel-1-img.png';
const img1 = '/images/landing/carousel-2-img.svg';
const img2 = '/images/landing/Spread 1.png';

function Carousel() {
  const projectsData = [
    {
      imageUrl: img,
      title: 'The easiest way to manage events',
      text: 'Embrace efficiency and seamlessness!',
      buttonUrl: 'https://scivenia.com/en/about',
    },
    {
      imageUrl: img1,
      title: 'Break all obstacles to science dissemination',
      text: 'Spread science worldwide!',
      buttonUrl: 'https://www.mubazar.com/en/about',
    },
    {
      imageUrl: img2,
      title: 'Collaborate with scientist worldwide',
      text: 'Bringing scientists together!',
      buttonUrl: 'https://www.gemone.com/en/about-us/',
    },
  ];
  const [open, setOpen] = React.useState(false);
  const openVideoModal = () => setOpen(true);
  const closeVideoModal = () => setOpen(false);

  return (
    <>
      <div id="projects" />
      <div className="relative">
        <div
          id="content"
          className="mx-auto max-w-7xl px-6 lg:px-8 pt-8 sm:pt-16 2xl:pt-32 pb-28 sm:pb-32 2xl:pb-48 items-center scroll-smooth scrollbar-hide"
        >
          <Swiper
            loop
            slidesPerView={1}
            spaceBetween={50}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            autoplay={{
              delay: 5000, // Set the delay between slides in milliseconds
              disableOnInteraction: false, // Continue autoplay after user interaction
            }}
            modules={[Pagination, Autoplay]}
            className="mySwiper self-center place-content-center"
          >
            {projectsData.map((project, index) => (
              <SwiperSlide key={`${index + 1}`}>
                <Card
                  imageUrl={project.imageUrl}
                  title={project.title}
                  description={project.description}
                  text={project.text}
                  buttonUrl={project.buttonUrl}
                  openVideoModal={openVideoModal}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      {open && (
        <div className="video-modal-overlay">
          <button
            onClick={closeVideoModal}
            type="button"
            className="video-close-button border-4 rounded-full p-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18.2997 5.7102C18.1129 5.52295 17.8592 5.41771 17.5947 5.41771C17.3302 5.41771 17.0766 5.52295 16.8897 5.7102L11.9997 10.5902L7.10973 5.7002C6.9229 5.51295 6.66924 5.40771 6.40473 5.40771C6.14021 5.40771 5.88656 5.51295 5.69973 5.7002C5.30973 6.0902 5.30973 6.7202 5.69973 7.1102L10.5897 12.0002L5.69973 16.8902C5.30973 17.2802 5.30973 17.9102 5.69973 18.3002C6.08973 18.6902 6.71973 18.6902 7.10973 18.3002L11.9997 13.4102L16.8897 18.3002C17.2797 18.6902 17.9097 18.6902 18.2997 18.3002C18.6897 17.9102 18.6897 17.2802 18.2997 16.8902L13.4097 12.0002L18.2997 7.1102C18.6797 6.7302 18.6797 6.0902 18.2997 5.7102Z"
                fill="white"
              />
            </svg>
          </button>
          <iframe
            width="1120"
            height="630"
            src="https://www.youtube.com/embed/0h6pmYnJDxU?si=gAGp-JgpYl5CHD65"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </div>
      )}
    </>
  );
}

export default Carousel;
