import React from 'react';
import Link from 'next/link';

import LandingStatistics from './LandingStatistics';

const Zorica = '/images/landing/Zorica.jpg';
const Darko = '/images/landing/Darko 1.png';
const Dane = '/images/landing/Dane 1.png';
const Daniel = '/images/landing/Daniel.jpg';
const Ben = '/images/landing/Ben.jpg';
const Marija = '/images/landing/Marija.jpg';
const Mudassar = '/images/landing/Mudassar.jpg';
const Mariche = '/images/landing/Mariche.jpg';
const Uchenna = '/images/landing/Uchenna.jpg';
const Dino = '/images/landing/Dino.jpg';
const Viktor = '/images/landing/viktor.jpg';

const people = [
  {
    name: 'DARKO BOZINOSKI',
    role: 'CEO',
    imageUrl: `${Darko}`,
    socialUrl: 'https://twitter.com/darko_bozinoski',
    liUrl: 'https://www.linkedin.com/in/darko-bozhinoski-ph-d-75bbb629/',
  },
  {
    name: 'JAMAL WAKIM',
    role: 'CFO',
    imageUrl: 'https://www.techvenia.com/img/Jamal.jpg',
    socialUrl: 'https://twitter.com/',
    liUrl: 'https://www.linkedin.com/in/jamal-wakim-phd-pharmd/',
  },
  {
    name: 'DANE JOSIFOVSKI',
    role: 'BUSINESS ANALYST',
    imageUrl: `${Dane}`,
    socialUrl: 'https://twitter.com/',
    liUrl: 'https://www.linkedin.com/in/dane-josifovski-b838ba52/',
  },
  {
    name: 'VIKTOR RISTEVSKI',
    role: 'CREATIVE DIRECTOR & PRODUCT DESIGNER',
    imageUrl: `${Viktor}`,
    socialUrl: 'https://twitter.com/',
    liUrl: 'https://www.linkedin.com/in/viktorristevski/?originalSubdomain=mk',
  },
  {
    name: 'MUDASSAR ALI',
    role: 'DEVELOPMENT LEAD',
    imageUrl: `${Mudassar}`,
    socialUrl: 'https://twitter.com/',
    liUrl: 'https://www.linkedin.com/in/mudsr-ali/',
  },
];

const team = [
  {
    name: 'BEN AWINO',
    role: 'SOFTWARE ENGINEER',
    imageUrl: `${Ben}`,
    socialUrl: 'https://twitter.com/',
    liUrl: 'https://www.linkedin.com/in/ben-awino-214b72100/',
  },
  {
    name: 'UCHENNA UGWUMADU',
    role: 'SOFTWARE ENGINEER',
    imageUrl: `${Uchenna}`,
    socialUrl: 'https://twitter.com/',
    liUrl: 'https://www.linkedin.com/in/josebright/',
  },
  {
    name: 'DANIEL ATONGE',
    role: 'SOFTWARE ENGINEER',
    imageUrl: `${Daniel}`,
    socialUrl: 'https://twitter.com/',
    liUrl: 'https://www.linkedin.com/in/danielatonge/',
  },
  {
    name: 'ZORICA MILOSHESKA',
    role: 'SOFTWARE ENGINEER',
    imageUrl: `${Zorica}`,
    socialUrl: 'https://twitter.com/',
    liUrl: 'https://www.linkedin.com/in/zorica-milosheska-5aa911232/',
  },
  {
    name: 'MARIJA MILEVSKA',
    role: 'SOFTWARE ENGINEER',
    imageUrl: `${Marija}`,
    socialUrl: 'https://twitter.com/',
    liUrl: 'https://www.linkedin.com/in/marija-milevska-87a87b16a/',
  },
  {
    name: 'DINO KULJEVAN',
    role: 'SOFTWARE ENGINEER',
    imageUrl: `${Dino}`,
    socialUrl: 'https://twitter.com/',
    liUrl: 'https://www.linkedin.com/in/dino-kuljevan-ab98b7215/',
  },
  {
    name: 'MARICHE TRPCHESKA',
    role: 'QA ENGINEER ',
    imageUrl: `${Mariche}`,
    socialUrl: 'https://twitter.com/',
    liUrl: '',
  },
];
export default function Example() {
  return (
    <div id='ourteam'>
      <section className="py-5">
        <div className="relative">
          <div className="bg-arrows-down-team" />
          <LandingStatistics value="OUR TEAM" />
          <div className="bg-waveTeam" />
          <div className="z-10 mt-5 mx-auto max-w-7xl px-6 lg:px-8">
            <div className="grid gap-x-8 gap-y-5">
              <div className="">
                <h2 className="subtitle titleTeamSection pb-7">
                  MANAGEMENT
                  <p className="titleTeamBorder" />
                </h2>
              </div>
              <ul className="grid gap-y-12 gap-x-0 grid-cols-1 md:grid-cols-4 lg:grid-cols-5 sm:gap-y-5 xl:col-span-2">
                {people.map((person) => (
                  <li key={person.name} className="mb-6">
                    <div className="flex flex-col">
                      <div className="team-list">
                        <Link href={person.liUrl} target="_blank">
                          <img
                            className="team-logo cursor-pointer flex-shrink-0 rounded-full self-center content-center place-items-center"
                            src={person.imageUrl}
                            alt=""
                          />
                        </Link>
                        <div className="teamPerson">
                          <div className="md:mt-4">
                            <h3 className="textName">{person.name}</h3>
                          </div>
                          <div>
                            <p className="dividerBorderTeam" />
                          </div>
                          <div className="text-center">
                            <p className="textRole">{person.role}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              {/* <div className="bg-wave"></div> */}
              <div className="">
                <h2 className="subtitle titleTeamSection py-7">
                  DEVELOPEMENT
                  <p className="titleTeamBorder" />
                </h2>
              </div>
              <ul className="grid gap-y-12 gap-x-0 grid-cols-1 md:grid-cols-4 lg:grid-cols-5 sm:gap-y-5 xl:col-span-2 mb-20 sm:mb-0">
                {team.map((person) => (
                  <li key={person.name} className="mb-6">
                    <div className="flex flex-col">
                      <div className="team-list">
                        <Link href={person.liUrl} target="_blank">
                          <img
                            className="team-logo cursor-pointer flex-shrink-0 rounded-full self-center content-center place-items-center"
                            src={person.imageUrl}
                            alt=""
                          />
                        </Link>
                        <div className="teamPerson">
                          <div className="md:mt-4">
                            <h3 className="textName">{person.name}</h3>
                          </div>
                          <div>
                            <p className="dividerBorderTeam" />
                          </div>
                          <div>
                            <p className="textRole">{person.role}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
