import React, {useState,useEffect} from 'react';
import PropTypes from 'prop-types'
import Carousel from 'react-multi-carousel';

import { redirectToRoute } from '@app/helpers';
import { useUser } from '@app/helpers/hooks';

// import { OPP_STATUS_ENUM } from '@app/constants';
import OpportunityCard from '@app/components/organisms/opportunities/OpportunityCard';
import { CardContainer, SliderContainer } from './style';


const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1,
    removeArrowOnDeviceType: true // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const handleViewOpportunity = slug => () => {
  redirectToRoute({}, 'view_opportunity', { params: { slug } });
};

// eslint-disable-next-line react/prop-types
const FeaturedSlider = ({ opportunities, responsivnes, ...props }) => {
  const { user }  = useUser();
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if(window.innerWidth < 600) {
      setIsMobile(true)
    }
  }, [])

  return (
    <SliderContainer>
      <Carousel
        swipeable={isMobile}
        draggable={false}
        showDots={false}
        responsive={responsivnes}
        ssr // means to render carousel on server-side.
        infinite
        //   autoPlay={this.props.deviceType !== "mobile" ? true : false}
        autoPlaySpeed={2000}
        // autoPlay
        keyBoardControl
        //   customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={['tablet', 'mobile']}
        //   deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        {...props}
      >
        {opportunities
          // .filter(opportunity => 
          //   !opportunity.is_draft && 
          //   opportunity.opp_status !== OPP_STATUS_ENUM.DRAFT && 
          //   !opportunity.is_private
          // )
          .map((opportunity) => (
            <CardContainer key={opportunity.slug}>
              <OpportunityCard
                userInfo={user}
                {...opportunity}
                onClick={handleViewOpportunity(opportunity.slug)}
              />
            </CardContainer>
          ))
        }
      </Carousel>
    </SliderContainer>
  );
};

FeaturedSlider.propTypes = {
  opportunities: PropTypes.arrayOf(PropTypes.object).isRequired,
  responsivnes: PropTypes.object,
};

FeaturedSlider.defaultProps = {
  responsivnes: responsive,
};

export default FeaturedSlider;
