import styled from 'styled-components';
import {makeStyles} from '@mui/styles';
import { devices, deviceSizes } from '../../../config/devices';

export const ButtonGroupBox = styled.div`
  position: absolute;
  top: 5px;
  right: 30px;
  button {
    width: 40px;
    height: 40px;
    background-color: #0A1744;
    color: white;
    border-radius: 50%;
    padding: 10px;
    box-shadow: none;
    margin: 5px;
    text-align: center;
    &:hover {
      background-color: #081E3F;
    }
  }

  @media (max-width: ${deviceSizes.sm}) {
    display: none;
  }
`;

export const useClasses = makeStyles({
  SliderStyle: {
    padding: '5rem',
    [`@media ${devices.xs}`]: {
      padding: '5rem 0 5rem 0',
    },
  },
  FiltersMainContainer: {
    [`@media(max-width: ${deviceSizes.sm})`]: {
      flexDirection: 'column-reverse',
    },
  },
  FiltersTitles: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [`@media(max-width: ${deviceSizes.md})`]: {
      justifyContent: 'center',
    },
  },
  CardsGroup: {
    [`@media(max-width: ${deviceSizes.md})`]: {
      display: 'none',
    },
  },
  Spacer: {
    width: '100%',
    height: '7rem',
    [`@media(max-width: ${deviceSizes.sm})`]: { height: '3rem ' },
  },
  HowItWorksCardMainContaniner: {
    justifyContent: 'center',
    alignItems: 'center',
    [`@media(max-width: ${deviceSizes.md})`]: {
      justifyContent: 'flex-start',
    },
  },
  ThreeRedCircles: {
    position: 'absolute',
    top: '300px',
    right: '315px',
    [`@media(min-width: ${deviceSizes.lg}) and (max-width: ${deviceSizes.laptopL})`]: {
      right: '215px',
    },
    [`@media(max-width: ${deviceSizes.lg})`]: {
      display: 'none',
    },
  },
  MessagesMainBox: {
    [`@media(max-width: ${deviceSizes.mobileL})`]: {
     flexDirection: 'column-reverse',
     flexWrap: 'nowrap',
     marginTop: '40px'
    },
  },
  MessagesStyledBox: {
    width: '40%',
    marginLeft: '50px',
    marginTop: '80px',
    [`@media(max-width: ${deviceSizes.md})`]: {
      width: '70%',
      marginLeft: '40px',
      marginTop: '30px',
    },
  },
  MusicBand: {
    position: 'absolute',
    top: '40px',
    left: '-40px',

    [`@media(max-width: ${deviceSizes.laptopL})`]: {
      top: '35px',
      left: '50px',
    },
    [`@media(max-width: ${deviceSizes.lg})`]: {
      top: '-30px',
      left: '-50px',
    },
    [`@media(max-width: ${deviceSizes.laptop})`]: {
      display: 'none',
    },
  },
  ContainerOverellipse: {
    marginTop: '-500px',
    justifyContent: 'center',
    [`@media(max-width: ${deviceSizes.md})`]: {
      marginTop: '-350px',
    },
    [`@media(max-width: ${deviceSizes.tablet})`]: {
      marginTop: '-170px',
    },
    [`@media(max-width: ${deviceSizes.sm})`]: {
      marginTop: '-90px',
    },
  },
  LargeElipseBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  Ellipse33: {
    position: 'absolute',
    top: '350px',
    left: '440px',
    [`@media(max-width: ${deviceSizes.lg})`]: {
      left: '170px',
    },
  },
  PaymentInfoBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    [`@media(max-width: ${deviceSizes.md})`]: {
      justifyContent: 'flex-start',
    },
  },
  ReviewMainContainer: {
    justifyContent: 'flex-end',
    [`@media(max-width: ${deviceSizes.lg})`]: {
      marginLeft: '40px',
    },
    [`@media(max-width: ${deviceSizes.md})`]: {
      marginLeft: '0',
    },
  },
  ReviewApplications: {
    justifyContent: 'center',
    [`@media(max-width: ${deviceSizes.lg})`]: {
      justifyContent: 'flex-start',
    },
  },
  JuriMainContenaier: {
    justifyContent: 'center',
    [`@media(max-width: ${deviceSizes.md})`]: {
      justifyContent: 'flex-start',
    },
  },
  JuriInfoBox: {
    width: '47%',
    [`@media(max-width: ${deviceSizes.md})`]: {
      width: '80%',
    },
  },
  ExportRedImages: {
    [`@media(max-width: ${deviceSizes.sm})`]: {
      display: 'none',
    },
  },
  EllipseReviewers: {
    position: 'absolute',
    top: '-214px',
    right: '-85px',
    [`@media(max-width: ${deviceSizes.sm})`]: {
      display: 'none',
    },
  },
  // ExportEverythingTable: {
  //   justifyContent: 'flex-end',
  //   [`@media(max-width: ${deviceSizes.sm})`]: {
  //     padding: '10px !important',
  //   },
  // },
});
