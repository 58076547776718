import React from 'react';

export default function LandingGradient() {
  return (
    <div className="relative landing-gradient-wrapper overflow-hidden flex items-stretch mb-32 mt-8 text-center">
      <img className="absolute mx-auto" src="/images/landing/main-sci-shape.svg" alt="" />
      <div className="mx-auto max-w-7xl px-6 lg:px-8 self-center place-content-center pl-10">
        <p>
          BRINGING <span>SCIENTISTS</span>
        </p>
        <p className="pt-6">TOGETHER</p>
      </div>
    </div>
  );
}
