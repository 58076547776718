import React from 'react';
import LandingEventsSlider from '@app/components/organisms/LandingEventsSlider';
import LandingStatistics from './LandingStatistics';

export default function LandingExploreEvents() {
  return (
    <div id='explore-events'>
        <section className="pt-5 pb-20">
            <LandingStatistics name="" value="EXPLORE EVENTS" />
            <LandingEventsSlider />
        </section>
    </div>
  );
}
