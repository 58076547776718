/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { Grid, Fab } from '@mui/material';
import { useQuery } from '@apollo/client';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { SEARCH_OPPORTUNITY } from '@app/apollo/queries';
import { useTranslation } from "@app/config/i18n";
import SearchIcon from '@mui/icons-material/Search';
import Button from "@app/components/atoms/Button";
import { redirectToRoute } from '@app/helpers';
import {
  ButtonGroupBox,
  useClasses,
} from './style';
import FeaturedSlider from '../../molecules/FeaturedSlider';


const LandingEventsSlider = () => {
  const { t } = useTranslation('common');
  const [play, setPlay] = useState(true);

  const { data: opportunitiesData } = useQuery(SEARCH_OPPORTUNITY, {
    variables: {
      first: 20,
      input: {
        upcoming: true,
      },
    },
  });

  const { opportunitySearch: { data: opportunities = [] } = {} } = opportunitiesData || {};

  const classes = useClasses()

  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <ButtonGroupBox>
        {' '}
        <Fab className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()}>
          <ChevronLeftIcon fontSize="small" />
        </Fab>
        <Fab onClick={() => setPlay(!play)}>
          {!play ? <PlayArrowIcon fontSize="small" /> : <PauseIcon fontSize="small" />}
        </Fab>
        <Fab onClick={() => next()}>
          <ChevronRightIcon fontSize="small" />
        </Fab>
      </ButtonGroupBox>
    );
  };

  ButtonGroup.propTypes = {
    next: PropTypes.func,
    previous: PropTypes.func,
  };
  ButtonGroup.defaultProps = {
    next: () => {},
    previous: () => {}
  }

  useEffect(() => {
    if (window.innerWidth < 600) {     
      setPlay(false);
    }
  }, []);

  const handleExploreOpportunities = () => {
    redirectToRoute({}, 'opportunity_search');
  };

 
  return (
    <>
      <div className="z-10">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FeaturedSlider
            className={classes.SliderStyle}
            autoPlay={play}
            arrows={false}
            customButtonGroup={<ButtonGroup />}
            opportunities={opportunities}
          />
        </Grid>
      </div>

      <Button
        variant="contained"
        // color="primary"
        style={{
            width: '20rem',
            paddingTop: '10px',
            paddingBottom: '10px',
            borderRadius: '10px',
            color: 'white',
            background: '#0A1744',
            boxShadow: '0px 4px 4px 0px #00000014',
            display: 'flex',
            margin: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        startIcon={<SearchIcon />}
        onClick={handleExploreOpportunities}
      >
        {t('common:explore_opportunities')}
      </Button>
    </>
  );
};

LandingEventsSlider.propTypes = {};

export default LandingEventsSlider;
