import React from "react";
import PropTypes from 'prop-types';

const PackagesCard = ({ backgroundColor, background, package1, numberSubmission, submission, numberEvent, storage, numberArchive, templates, trackEvent, agenda, financialData, price, disscount, disscount1 }) => (
    <>
        <div className="flex flex-col custom-container has-hover-card wd"
        >
            <div className={`${backgroundColor} linear-m flex-none z-40`}>
                <div className="cardTitle">
                    <div className={`${background} rectangle`} />
                    <h3 className="cardPackageTitle">{package1}</h3>
                    <p className="cardPackageSubTitle">PACKAGE</p>
                </div>
            </div>


            {/* <div className=""> */}
            <ul className="mb-8 text-left card-data flex-auto h-60 pt-8 package-offers">
                {numberSubmission && (
                    <li className="flex items-center space-x-3">

                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                        <span className="pr-2 font-semibold">{numberSubmission}</span> {submission}
                    </li>
                )}
                {numberEvent && (
                    <li className="flex items-center space-x-3">

                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                        <span className="pr-2 font-semibold">{numberEvent}</span> Free event
                    </li>
                )}
                {storage && (
                    <li className="flex items-center space-x-3">

                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                        <span className="pr-2 font-semibold">{storage}</span>storage
                    </li>
                )}
                {numberArchive && (
                    <li className="flex items-center space-x-3">

                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                        <span >Archive up to <span className="pr-2 font-semibold">{numberArchive}</span>years</span>
                    </li>
                )}

                <li className="flex items-center space-x-3">

                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                    <span>‘All at once’ event service</span>
                </li>


                <li className="flex items-center space-x-3">

                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                    <span>Data analytics</span>
                </li>


                <li className="flex items-center space-x-3">

                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                    <span>Branded event website</span>
                </li>

                {templates && (
                    <li className="flex items-center space-x-3">

                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                        <span>{templates}</span>
                    </li>
                )}
                {trackEvent && (
                    <li className="flex items-center space-x-3">

                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                        <span>{trackEvent}</span>
                    </li>
                )}
                {agenda && (
                    <li className="flex items-center space-x-3">

                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                        <span>{agenda}</span>
                    </li>
                )}
                {financialData && (
                    <li className="flex items-center space-x-3">

                        <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                        <span>{financialData}</span>
                    </li>
                )}
            </ul>

            <div className="flex flex-col justify-end flex-1 pb-10">

                <div className="">
                    <div className="flex justify-center items-baseline pb-4">
                        <span className="mr-2 text-5xl package-price-tag">{price}€</span>
                    </div>
                    <p className=" pb-2 text-center package-service-fee">+5.9% SciVenia service fee</p>
                    <div className="border-t mx-14" />
                    <p className="text-[#757575] font-small text-xs text-center pt-2 pb-1">{disscount}</p>
                    <p className="text-[#757575] font-small text-xs text-center">{disscount1}</p>
                </div>
            </div>
            {/* </div> */}


        </div>
    </>
)

PackagesCard.propTypes = {
    backgroundColor: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
    package1: PropTypes.string.isRequired,
    numberSubmission: PropTypes.any.isRequired,
    submission: PropTypes.string.isRequired,
    numberEvent: PropTypes.any.isRequired,
    storage: PropTypes.any.isRequired,
    numberArchive: PropTypes.any.isRequired,
    templates: PropTypes.any.isRequired,
    trackEvent: PropTypes.any.isRequired,
    agenda: PropTypes.any.isRequired,
    financialData: PropTypes.any.isRequired,
    price: PropTypes.string.isRequired,
    disscount: PropTypes.any.isRequired,
    disscount1: PropTypes.any.isRequired,
};

export default PackagesCard;