import styled, { css } from 'styled-components';

const SpacerElement = styled.div`
  ${({ grow }) => grow && 'flex-grow: 1;'};

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};

  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `};

	display: ${props => props.display};
`;

export default SpacerElement;
