import React from 'react';
import PropTypes from 'prop-types';

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content ">
                <div className="mx-auto max-w-[76rem] py-10">
                    <div className="modal-button-text flex">
                        <p />
                        <button type="button" className="close-button" onClick={onClose}>
                            X
                        </button>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
};

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.any.isRequired,
};

export default Modal;