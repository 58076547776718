import React from 'react';
import PropTypes from 'prop-types';
import SpacerElement from './style';

const Spacer = ({ grow, width, height, display }) => (
  <SpacerElement grow={grow} width={width} height={height} display={display} />
);

Spacer.propTypes = {
  grow: PropTypes.bool,
  width: PropTypes.string,
	height: PropTypes.string,
	display: PropTypes.string,
};

Spacer.defaultProps = {
  grow: false,
  width: null,
	height: null,
	display: 'block',
};

export default Spacer;
