/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';

const ReadMoreButtonWithModal = ({ initialText, expandedText, name, title }) => {
    const [showModal, setShowModal] = useState(false);

    const handleButtonClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div>
            <p className="mt-[12px] sm:mt-[18px] text mb-1">
                {initialText}
            </p>
            <button type="button" className='mt-[12px] sm:mt-[18px] border-b readmore-text' onClick={handleButtonClick}>
                Read more →
            </button>

            {showModal && (
                <Modal
                    isOpen
                    onClose={handleCloseModal}
                >
                    <div>
                        <p className="text-white font-lato text-2xl w-[320px] lg:w-[700px] font-normal leading-110 uppercase pb-5">
                            {name}
                        </p>
                        <p className="mt-[12px] sm:mt-[18px] text">
                            {expandedText}
                        </p>
                    </div>
                </Modal>
            )}
        </div>
    );
};

ReadMoreButtonWithModal.propTypes = {
    initialText: PropTypes.string,
    expandedText: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string
};

ReadMoreButtonWithModal.defaultProps = {
    initialText: '',
    expandedText: '',
    name: '',
    title: ''
}

export default ReadMoreButtonWithModal;